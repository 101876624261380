import { MtDate } from '@mt-ng2/date-module';
import moment from 'moment-timezone';

export const getUserTimezone = () => {
    return moment.tz.guess(true);
};

export const getAllTimezones = () => {
    return moment.tz.names();
};

export const convertToTimezone = (date: Date, timezone: string | null): Date | null => {
    if (!date) { return null; }
    if (!timezone) { return date; }

    // Extract components from the input date
    const year = date.getFullYear();
    const month = date.getMonth(); // Note: month is 0-indexed
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    // Create a moment object using the extracted components in the target timezone
    const targetTime = moment.tz({ year, month, day, hour, minute, second }, timezone);

    // Convert the target time to the local timezone
    const localTime = targetTime.clone().local();

    // Return the local time as a JavaScript Date object
    return localTime.toDate();
};

export const formatToTimezone = (date: Date | null, timezone: string | null, format: string): string => {
    if (!date) {
        return '';
    }

    const momentDate = moment(date);
    const convertedMoment = timezone ? momentDate.tz(timezone) : momentDate.local();
    const formattedDate = convertedMoment.format(format);

    return formattedDate;
}
