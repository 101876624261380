export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    UserRoles = 3,
    Audit = 4,
    AccessRequests = 5,
    Programs = 6,
    Regions = 7,
    Seasons = 8,
}
