import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TimezoneService {
    private timezones: Map<string, string> = new Map();

    setTimezone(componentId: string, timezone: string): void {
        this.timezones.set(componentId, timezone);
    }

    getTimezone(componentId: string): string | null{
        return this.timezones.get(componentId) || null;
    }
}
