import { Component, ChangeDetectorRef } from '@angular/core';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';

@Component({
    styles: [
        `
            .inside-box-validation {
                position: relative !important;
                top: -35px;
                bottom: -35px;
                margin-bottom: -35px;
                right: 3px;
                float: right;
                font-size: 70%;
            }
            .inside-box-maxlength {
                position: relative !important;
                bottom: -13px;
                top: -13px;
                margin-bottom: -13px;
                right: 3px;
                font-size: 70%;
            }
            label {
                margin-top: 10px;
                margin-bottom: 0px;
            }
        `,
    ],
    templateUrl: './custom-url-fields.component.html',
})
export class CustomUrlFieldsComponent extends CustomFormControlComponentBase {
    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.subscribeToStatusChanges();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.formatUrlFieldElement();
    }

    subscribeToStatusChanges(): void {
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                this.inputElement.nativeElement.disabled =
                    status === 'DISABLED';
            }),
        );
    }

    formatUrlFieldElement(): void {
        this.inputElement.nativeElement.value = this.config.value
    }

    onBlur(): void {
        if (this.getControl().enabled) {
            this.setFieldValue();
            this._isFocused = false;
        }
    }

    setFieldValue(): void {
        const control = this.getControl();
        const urlFieldControl = this.inputElement;
        const value = urlFieldControl.nativeElement.value;
        if (control.value !== value) {
            control.setValue(value);
            control.markAsDirty();
        }
    }
}

