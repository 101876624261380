
import { Component, EventEmitter, inject, Injector, Input, OnInit, Output } from '@angular/core';
import { IDocumentUploadControlConfig } from '@mt-ng2/entity-components-documents';
import { saveAs } from 'file-saver';
import { FormGroup, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { FileItem } from 'ng2-file-upload';
import { EntityListConfig, IColumnSortedEvent, IItemSelectedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { DynamicField, IDynamicConfig } from '@mt-ng2/dynamic-form';
import { IHasExtendedDocuments } from '@model/shared-entities/documents/has-extended-documents';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { IEntityRouteConfig } from '@mt-ng2/entity-components-base';
import { IExtendedDocument } from '@model/shared-entities/documents/extended-document';
import { entityListModuleConfig } from '@common/shared.module';

@Component({
    selector: 'app-common-form-documents',
    templateUrl: 'common-form-documents.component.html',
})
export class CommonFormDocumentsComponent implements OnInit {
    protected _isEditing = false;
    @Input()
    set isEditing(value: boolean) {
        this.checkNull(value, 'isEditing');
        this._isEditing = value;
    }

    _total = 0;
    @Input()
    set total(value: number) {
        this.checkNull(value, 'total');
        this._total = value;
    }

    _max: number;
    @Input()
    set max(value: number) {
        this.checkNull(value, 'max');
        this._max = value;
    }

    protected _canEdit?: boolean;
    @Input()
    get canEdit(): boolean {
        return this._canEdit ?? false;
    }
    set canEdit(value: boolean) {
        this.checkNull(value, 'canEdit');
        this._canEdit = value;
    }

    protected _canAdd?: boolean;
    @Input()
    get canAdd(): boolean {
        return this._canAdd ?? false;
    }
    set canAdd(value: boolean) {
        this.checkNull(value, 'canAdd');
        this._canAdd = value;
    }

    protected _documentsPassedIn = false;
    protected _documentArray: IExtendedDocument[] = [];
    @Input()
    set documentArray(value: IExtendedDocument[]) {
        this.checkNull(value, 'documentArray');
        this._documentArray = value;
        this._documentsPassedIn = true;
    }
    get documentArray(): IExtendedDocument[] {
        return this._documentArray;
    }

    protected _service?: IHasExtendedDocuments<unknown> | null;
    @Input()
    set service(value: IHasExtendedDocuments<unknown> | null) {
        this._service = value;
    }
    get service(): IHasExtendedDocuments<unknown> | null {
        if (!this._documentsPassedIn && this._service && this.parentId && this.parentId > 0) {
            return this._service;
        }
        return null;
    }

    protected _documentUploadControlConfig?: IDocumentUploadControlConfig;
    @Input()
    set documentUploadControlConfig(value: IDocumentUploadControlConfig | undefined) {
        this._documentUploadControlConfig = value;
    }
    get documentUploadControlConfig(): IDocumentUploadControlConfig | undefined {
        return this._documentUploadControlConfig;
    }

    protected _allowedMimeType?: string[];
    @Input()
    set allowedMimeType(value: string[]) {
        this._allowedMimeType = value;
    }

    protected _formFactory: IDynamicConfig<unknown>
    @Input() set formFactory(value: IDynamicConfig<unknown>) {
        const config = value.getForCreate();
        this.documentFormFields = config.formObject.map((field) => {
            field.formGroup = 'Document';
            return field;
        });
        this._formFactory = value;
    }
    @Input() wrongFileTypeErrorMessage: string;
    @Input() set maximum(value: string) {
        this.max = parseInt(value, 10);
    }

    protected _entityListConfig: EntityListConfig;
    @Input() set entityListConfig(value: EntityListConfig) {
        if (value) {
            this._entityListConfig = value;
            this.order = this._entityListConfig.getDefaultSortProperty();
            this.orderDirection = this._entityListConfig.getDefaultSortDirection();
        }
    }
    @Input() entities: IExtendedDocument[];

    @Output() documentAdded: EventEmitter<FileItem> = new EventEmitter<FileItem>();
    @Output() validateFailed: EventEmitter<string> = new EventEmitter<string>();
    @Output() documentDeleted: EventEmitter<IExtendedDocument> = new EventEmitter<IExtendedDocument>();
    @Output() documentDownloaded: EventEmitter<IExtendedDocument> = new EventEmitter<IExtendedDocument>();
    @Output() formCreated: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

    currentPage = 1;
    itemsPerPage = this.max;
    order: string | undefined;
    orderDirection: string;

    parentId!: number;
    formObject: DynamicField[];
    documentForm: FormGroup;
    documentFileUpload: FileItem;
    newDocument = false;
    showFormArea = false;
    showUploadDocument = false;
    documentUpdated = false;
    selectedDocument: IExtendedDocument;
    documentFormFields: DynamicField[];
    selectedDocumentFile: { Name: string; Id: number };

    private fb = inject(FormBuilder);
    private route = inject(ActivatedRoute);
    private injector = inject(Injector);
    protected notificationsService: NotificationsService = inject(NotificationsService);
    protected claimsService: ClaimsService = inject(ClaimsService);

    constructor() {
        //empty
    }

    ngOnInit(): void {
        this.setDocumentForm();
        this.setVariables();
        this.isEditing = false;
        this.getDocuments();

    }

    setVariables(): void {
        const config: IEntityRouteConfig = <IEntityRouteConfig>this.route.parent?.snapshot.data;
        if (config && config.entityIdParam) {
            this.parentId = +(this.route.parent?.snapshot.paramMap.get(config.entityIdParam) ?? 0);
        }
        const service = this.service || (config && config.service) || null;
        if (service) {
            if (typeof service === 'function') {
                this.service = this.injector.get(service);
            }
        }
        if (this._canEdit === undefined) {
            this._canEdit = config && config.claimType ? this.claimsService.hasClaim(config.claimType, [ClaimValues.FullAccess]) : false;
        }
        if (this._canAdd === undefined) {
            this._canAdd = this._canEdit;
        }
        if (this._documentUploadControlConfig === undefined) {
            if (this._service && this._service.documentUploadControlConfig) {
                this._documentUploadControlConfig = this._service.documentUploadControlConfig;
            } else {
                this._documentUploadControlConfig = {};
            }
        }
        this._documentUploadControlConfig.allowedMimeType = this._allowedMimeType ||
            this.documentUploadControlConfig?.allowedMimeType || ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'application/zip'];
    }

    get showUploadArea(): boolean {
        return !!(this._isEditing && this._canEdit);
    }

    getDocuments(): void {
        if (this.service) {
            const searchparamProperties: IEntitySearchParams = {
                order: this.order,
                orderDirection: this.orderDirection,
                query: '',
                skip: (this.currentPage - 1) * this.itemsPerPage,
                take: this._max,
            };
            const searchparams = new SearchParams(searchparamProperties);

            this.service.getExtendedDocuments(this.parentId, searchparams).subscribe((response) => {
                this._documentArray = response.body as IExtendedDocument[] ?? [];
                this._total = +(response.headers.get('X-List-Count') ?? 0);
            });
        }
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getDocuments();
    }

    documentFormCreated(event: UntypedFormGroup): void {
        this.setForm(this.selectedDocument);
        this.formCreated.emit(event);
    }

    setDocumentForm(): void {
        this.documentForm = this.fb.group({
            Document: this.fb.group({}),
        });
        this.documentFileUpload = undefined;
    }

    addFile(file: FileItem): void {
        this.documentFileUpload = file;
        this.documentUpdated = true;
    }

    addingFailed(message: string): void {
       if (message.includes('Maximum')) {
            const pieces = message.match(/.* [(](.+) of (\w+).*/);

            const mb = 1024 * 1024;
            const fileSizeInMB = +pieces[1] / mb;
            const maxInMB = +pieces[2] / mb;

            this.notificationsService.error(`Maximum upload size exceeded (${ fileSizeInMB.toFixed(2) } MB of ${ maxInMB.toFixed(2) } MB allowed)`);

        } else if (message.includes('Allowed types')) {
            const errorMessage = message.substring(0, message.indexOf('Allowed types:')) + this.wrongFileTypeErrorMessage;
            this.notificationsService.error(errorMessage);
        } else {
            this.notificationsService.error('Adding file failed.');
        }
        this.documentFileUpload = undefined;
    }

    cancel(): void {
        this.isEditing = false;
        this.selectedDocument = undefined;
        this.newDocument = false
        this.showUploadDocument = true;
        this.documentUpdated = false;
    }

    success(): void {
        this.getDocuments();
        this.newDocument = false;
        this.isEditing = false;
        this.selectedDocument = undefined;
        this.documentUpdated = false;
        this.documentFileUpload = undefined;
        if (this.newDocument) {
            this.notificationsService.success('Added successfully');
        }
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            if (this.documentUpdated) {
                this.selectedDocument.Document = null;
            }
            this._formFactory.assignFormValues(this.selectedDocument, form.getRawValue().Document);
            if (this.newDocument) {
                this.service.createExtendedDocument(this.parentId, this.documentFileUpload?._file, this.selectedDocument).subscribe(() => {
                    this.success();
                });
            } else {
                this.service.updateExtendedDocument(this.parentId, this.documentFileUpload?._file, this.selectedDocument, this.documentUpdated).subscribe(() => {
                    this.success();
                });
            }
        } else {
            this.notificationsService.error('Save failed.  Please check the form and try again.');
        }
    }

    addDocument(): void {
        const config = this.formFactory.getForCreate();
        this.formObject = config.formObject;
        this.isEditing = true;
    }

    setAdd(): void {
        this.newDocument = true;
        this.isEditing = true;
        this.selectedDocument = this._service.getEmptyExtendedDocument() as IExtendedDocument;
        this.showUploadDocument = true;
        this.documentUpdated = true;
    }

    setEdit(selectedDocument: IItemSelectedEvent): void {
        if (this.canEdit) {
            this.isEditing = true;
            this.newDocument = false;
            this.showUploadDocument = true;
            this.selectedDocument = selectedDocument.entity as IExtendedDocument;
            if (this.selectedDocument.DocumentId !== null) {
                this.showUploadDocument = false;
                this.selectedDocumentFile = {
                    Name: selectedDocument.entity.Document.Name,
                    Id: selectedDocument.entity.Document.Id
                };
            }
        }
    }

    setForm(document: unknown): void {
        const documentFormGroup: FormGroup = this.documentForm.get(['Document']) as FormGroup;
        for (const field in documentFormGroup.controls) {
            const documentFormFields = this.documentFormFields.find((x) => x.name === field);
            documentFormFields.value = document[field];

            const control = documentFormGroup.controls[field];
            control.patchValue(document[field]);
            control.markAsPristine();
        }
    }

    deleteDocument(event: IItemSelectedEvent): void {
        const document = event.entity as IExtendedDocument;
        this.service.deleteExtendedDocument(this.parentId, document.Id).subscribe(() => {
            this.success();
        });
    }

    downloadDocument(event: IItemSelectedEvent): void {
        const extendedDocument = event.entity as IExtendedDocument;
        if (extendedDocument.Document) {
            this.service.downloadDocument(extendedDocument).subscribe((x) => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const thefile = new Blob([x], { type: 'application/octet-stream' });
                const fileName = extendedDocument.Document.Name;
                saveAs(thefile, fileName);
            });
        }
    }

    deleteDocumentDoc(): void {
        this.documentUpdated = true;
        this.showUploadDocument = true;
        this.documentFileUpload = undefined;
    }

    private checkNull(value: unknown, name: string): void {
        if (value === null) {
            throw new Error(`@mt-ng2/entity-components-documents : CommonDocumentsComponent : ${name} cannot be set to null.`);
        }
    }
}
