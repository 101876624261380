import { DatePipe } from '@angular/common';
import { Component, Injector, ViewChild, ElementRef, OnInit, inject } from '@angular/core';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';
import { TimezoneService } from './timezone.service';
import { convertToTimezone, formatToTimezone } from '@common/libraries/timezone.library';

@Component({
    templateUrl: './custom-date-time-input.component.html',
})
export class CustomDateTimeInputComponent extends CustomFormControlComponentBase implements OnInit{
    @ViewChild('input', { static: false }) override inputElement!: ElementRef;
    displayValue = '';
    timezone: string | null = null;
    datePipe = inject(DatePipe);
    timezoneService = inject(TimezoneService);

    constructor(injector: Injector) {
        super(injector);
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.timezone = this.timezoneService.getTimezone(this.config.name);
        if (this.config.value) {
            this.config.value = new Date(this.config.value.toString());
            if (!isNaN(this.config.value.getTime())) {
                this.setDisplayValue(this.config.value);
            }
        }
        this.subscriptions.add(
            this.getControl()?.valueChanges.subscribe((value) => {
                this.setDisplayValue(value as Date);
            }),
        );
    }

    setDisplayValue(value: Date): void {
        const formattedDate = formatToTimezone(value, this.timezone, 'YYYY-MM-DDTHH:mm');
        this.displayValue = formattedDate;
    }

    override onBlur(): void {
        this.updateDateValue();
        this.customBlur.emit();
    }

    updateDateValue(): void {
        const dateControl = this.inputElement;
        const control = this.getControl();

        const valueToSet = dateControl.nativeElement.value ? new Date(dateControl.nativeElement.value as Date) : null;
        control?.patchValue(convertToTimezone(valueToSet, this.timezone));
        control?.markAsDirty();
    }
}
